<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet v-if="$vuetify.breakpoint.mdAndUp">
            <v-sheet style="padding-top:100px; padding-bottom:100px;">
                <v-sheet width="1274" color="transparent" class="mt-10 mb-10 mx-auto">
                    <p class="text-center text-h4 font-weight-bold">서비스 이용요금</p>
                </v-sheet>
                <v-sheet width="1274" color="#f9f9f9" class="pa-5 mx-auto">
                    <p class="text-h5 font-weight-bold text-center pt-10 pb-10">위셀페이 기본사양</p>
                    <v-sheet color="transparent">
                        <v-sheet color="transparent" width="100%">
                            <p class="text-h6 font-weight-bold text-center">서비스 사양</p>
                            <v-divider class="mb-4"></v-divider>
                            <table class="cs">
                                <tr>
                                    <td>상품 등록</td>
                                  <td>무제한 ( 조건에 따라 상이함 )</td>
                                </tr>
                                <tr>
                                    <td>저장 공간</td>
                                    <td>무제한 ( 조건에 따라 상이함 )</td>
                                </tr>
                                <tr>
                                    <td>트래픽 용량</td>
                                    <td>무제한 ( 조건에 따라 상이함 )</td>
                                </tr>
                                <tr>
                                    <td>디자인</td>
                                    <td>기본 디자인 무료 제공 </td>
                                </tr>
                                <tr>
                                    <td>PC / 모바일 쇼핑몰</td>
                                    <td>반응형 지원 및 무료 제공 </td>
                                </tr>
                                <tr>
                                    <td>결제 수단</td>
                                  <td>신용카드 , 간편통합결제, 계좌이체, 가상계좌</td>
                                </tr>
                            </table>
                        </v-sheet>
                        <v-sheet color="transparent" width="100%" class="mt-14">
                          <p class="text-h6 font-weight-bold text-center">서비스 이용요금</p>
                          <v-divider class="mb-4"></v-divider>
                          <p class="mr-4 py-3 text-center">위셀페이 판매자는 <span class="font-weight-bold" style="color: #ac2525">PG 가입비 무료</span>로 제공해 드리며, 타사 대비 매우 저렴한 수수료를 제공해 드립니다.</p>
                          <table class="adjustment2 " :style="$vuetify.breakpoint.mdAndUp? 'width:850px;':'width:100%; font-size:9pt;'">
                            <tr>
                              <td class="adj_color">결제수단</td>
                              <td class="adj_color">가맹점 구분</td>
                              <td class="adj_color font-weight-bold">수수료<br><span class="text-subtitle-2 font-weight-bold">(VAT별도)</span></td>
                            </tr>
                            <tr>
                              <td rowspan="5" class="adj_color">국내 신용카드</td>
                              <td>영세</td>
                              <td>1.6%</td>
                            </tr>
                            <tr>
                              <td>중소1</td>
                              <td>2.2%</td>
                            </tr>
                            <tr>
                              <td>중소2</td>
                              <td>2.35%</td>
                            </tr>
                            <tr>
                              <td>중소3</td>
                              <td>2.6%</td>
                            </tr>
                            <tr>
                              <td>일반</td>
                              <td>3.0%</td>
                            </tr>
                            <tr>
                              <td class="adj_color">
                                <div>
                                  간편통합결제
                                </div>
                                <div class="text-caption font-weight-bold">
                                  (카카오페이,네이버페이,SSG,LPAY,PAYCO)
                                </div>
                              </td>
                              <td>일반</td>
                              <td>3.0%</td>
                            </tr>
                            <tr>
                              <td class="adj_color">계좌이체</td>
                              <td>-</td>
                              <td>2%(최저 200원)</td>
                            </tr>
                            <tr>
                              <td class="adj_color">가상계좌</td>
                              <td>-</td>
                              <td>350원/건</td>
                            </tr>
                            <!--                    <tr>
                                                    <td class="adj_color">카카오페이</td>
                                                    <td>
                                                    <td>3.4%</td>
                                                    <td>3.4%</td>
                                                </tr>
                                                <tr>
                                                    <td class="adj_color">네이버페이</td>
                                                    <td>
                                                    <td>3.4%</td>
                                                    <td>3.4%</td>
                                                </tr>-->
                          </table>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
                <v-sheet width="1274" class="mt-16 mb-10 mx-auto">
                    <p class="text-center text-h4 font-weight-bold">정산</p>
                    <p class="text-center text-h5 font-weight-bold">위셀페이는 가입 즉시 모든 결제수단 이용 가능합니다.</p>
                </v-sheet>
                <table class="adjustment">
                    <tr>
                        <td class="adj_color">구분</td>
                        <td class="adj_color">신용카드</td>
                        <td class="adj_color">간편통합결제</td>
                        <td class="adj_color">계좌이체</td>
                        <td class="adj_color">가상계좌</td>
<!--                        <td class="adj_color">카카오페이</td>
                        <td class="adj_color">네이버페이</td>-->
                    </tr>
                    <tr>
                        <td class="adj_color">정산주기</td>
                        <td>영업일 기준<br/>D + 7일</td>
                        <td>영업일 기준<br/>D + 7일</td>
                        <td>영업일 기준<br/>D + 7일</td>
                        <td>영업일 기준<br/>D + 7일</td>
<!--                        <td>영업일 기준<br/>D + 7일</td>
                        <td>영업일 기준<br/>D + 7일</td>-->
                    </tr>
                </table>
                <v-sheet width="1274" class="mt-6 mb-10 mx-auto">
                    <p class="text-center text-subtitle-2 font-weight-bold mb-2" style="color:#ac2525;">※ 기본 정산주기는 D+7일 입니다.</p>
                    <p class="text-center text-subtitle-2 font-weight-bold" style="color:#ac2525;">※ 정산에 필요한 서류를 제출하지 않으시면 정산금을 지급받을 수 없습니다.</p>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- 모바일 -->
        <v-sheet v-if="!$vuetify.breakpoint.mdAndUp">
            <v-sheet style="padding-bottom:100px;">
                <v-sheet color="transparent" class="mt-10 mb-10 mx-auto">
                    <p class="text-center text-h4 font-weight-bold">서비스 이용요금</p>
                </v-sheet>
                <v-sheet color="#f9f9f9" class="py-5 px-7 mx-auto">
                    <p class="text-h5 font-weight-bold text-center pt-10 pb-10">위셀페이 기본사양</p>
                    <v-sheet color="transparent">
                        <v-sheet color="transparent">
                            <p class="text-h6 font-weight-bold">서비스 사양</p>
                            <v-divider class="mb-4"></v-divider>
                            <table class="cs_mobile text-caption">
                                <tr>
                                    <td>상품 등록</td>
                                    <td>무제한 ( 조건에 따라 상이함 )</td>
                                </tr>
                                <tr>
                                    <td>저장 공간</td>
                                    <td>무제한 ( 조건에 따라 상이함 )</td>
                                </tr>
                                <tr>
                                    <td>트래픽 용량</td>
                                    <td>무제한 ( 조건에 따라 상이함 )</td>
                                </tr>
                                <tr>
                                    <td>디자인</td>
                                    <td>기본 디자인 무료 제공 </td>
                                </tr>
                                <tr>
                                    <td>PC / 모바일<br/>쇼핑몰</td>
                                    <td>반응형 지원 및 무료 제공 </td>
                                </tr>
                                <tr>
                                    <td>결제 수단</td>
                                    <td>신용카드 , 간편통합결제, 계좌이체, 가상계좌</td>
                                </tr>
                            </table>
                        </v-sheet>
                        <v-sheet color="transparent" class="mt-10 mb-16">
                            <p class="text-h6 font-weight-bold">서비스 이용요금</p>
                            <v-divider class="mb-4"></v-divider>
                            <p class="mr-4 text-body-2">위셀페이 판매자는 PG 가입비 무료로 제공해 드리며, 타사 대비 매우 저렴한 수수료를 제공해 드립니다.</p>
                          <table class="adjustment_mobile " :style="$vuetify.breakpoint.mdAndUp? 'width:850px;':'width:100%; font-size:9pt;'">
                            <tr>
                              <td class="adj_color" style="width: 100px">결제수단</td>
                              <td class="adj_color">가맹점 구분</td>
                              <td class="adj_color font-weight-bold">수수료<br><span class="text-subtitle-2 font-weight-bold">(VAT별도)</span></td>
                            </tr>
                            <tr>
                              <td rowspan="5" class="adj_color">국내 신용카드</td>
                              <td>영세</td>
                              <td>1.6%</td>
                            </tr>
                            <tr>
                              <td>중소1</td>
                              <td>2.2%</td>
                            </tr>
                            <tr>
                              <td>중소2</td>
                              <td>2.35%</td>
                            </tr>
                            <tr>
                              <td>중소3</td>
                              <td>2.6%</td>
                            </tr>
                            <tr>
                              <td>일반</td>
                              <td>3.0%</td>
                            </tr>
                            <tr>
                              <td class="adj_color">
                                <div>
                                  간편통합결제
                                </div>
                                <div class="text-caption font-weight-bold">
                                  (카카오페이,네이버페이,SSG,LPAY,PAYCO)
                                </div>
                              </td>
                              <td>일반</td>
                              <td>3.0%</td>
                            </tr>
                            <tr>
                              <td class="adj_color">계좌이체</td>
                              <td>-</td>
                              <td>2%(최저 200원)</td>
                            </tr>
                            <tr>
                              <td class="adj_color">가상계좌</td>
                              <td>-</td>
                              <td>350원/건</td>
                            </tr>
                            <!--                    <tr>
                                                    <td class="adj_color">카카오페이</td>
                                                    <td>
                                                    <td>3.4%</td>
                                                    <td>3.4%</td>
                                                </tr>
                                                <tr>
                                                    <td class="adj_color">네이버페이</td>
                                                    <td>
                                                    <td>3.4%</td>
                                                    <td>3.4%</td>
                                                </tr>-->
                          </table>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
                <v-sheet class="mt-16 mb-10 mx-auto">
                    <p class="text-center text-h4 font-weight-bold">정산</p>
                    <p class="text-center text-subtitle-1 font-weight-bold">위셀페이는 가입 즉시 모든 결제수단 이용 가능합니다.</p>
                </v-sheet>
                <v-sheet class="px-2">
                    <table class="adjustment_mobile text-body-2 text-caption" style="width: 100%">
                        <tr>
                            <td class="adj_color">구분</td>
                            <td class="adj_color">신용카드</td>
                            <td class="adj_color">간편통합결제</td>
                            <td class="adj_color">계좌이체</td>
                            <td class="adj_color">가상계좌</td>
<!--                            <td class="adj_color">카카오페이</td>
                            <td class="adj_color">네이버페이</td>-->
                        </tr>
                        <tr>
                            <td class="adj_color">정산주기</td>
                            <td>영업일 기준<br/>D + 7일</td>
                            <td>영업일 기준<br/>D + 7일</td>
                            <td>영업일 기준<br/>D + 7일</td>
                            <td>영업일 기준<br/>D + 7일</td>
<!--                            <td>영업일 기준<br/>D + 7일</td>
                            <td>영업일 기준<br/>D + 7일</td>-->
                        </tr>
                    </table>
                </v-sheet>
                <v-sheet class="mt-6 mb-10 px-3 mx-auto">
                    <p class="text-center text-subtitle-2 font-weight-bold mb-2" style="color:#ac2525;">※ 기본 정산주기는 D+7일 입니다.</p>
                    <p class="text-center text-subtitle-2 font-weight-bold" style="color:#ac2525;">※ 정산에 필요한 서류를 제출하지 않으시면 정산금을 지급받을 수 없습니다.</p>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- Dialog -->
        <v-dialog v-model="dialog" :width="$vuetify.breakpoint.mdAndUp? '882':'100%'">
            <v-card class="pa-4">
                <table class="adjustment2" :style="$vuetify.breakpoint.mdAndUp? 'width:850px;':'width:100%; font-size:9pt;'">
                    <tr>
                        <td class="adj_color">결제수단</td>
                        <td class="adj_color">가맹점 구분</td>
                        <td class="adj_color font-weight-bold">위셀페이 신규 가입 할인수수료</td>
                        <td class="adj_color">위셀페이 수수료</td>
                    </tr>
                    <tr>
                        <td rowspan="5" class="adj_color">국내 신용카드</td>
                        <td>영세</td>
                        <td>1.6%</td>
                        <td>1.75%</td>
                    </tr>
                    <tr>
                        <td>중소1</td>
                        <td>2.2%</td>
                        <td>2.35%</td>
                    </tr>
                    <tr>
                        <td>중소2</td>
                        <td>2.35%</td>
                        <td>2.5%</td>
                    </tr>
                    <tr>
                        <td>중소3</td>
                        <td>2.6%</td>
                        <td>2.75%</td>
                    </tr>
                    <tr>
                        <td>일반</td>
                        <td>3.0%</td>
                        <td>3.1%</td>
                    </tr>
                  <tr>
                    <td class="adj_color">
                      <div>
                        간편통합결제
                      </div>
                      <div class="text-caption font-weight-bold">
                        (카카오페이,네이버페이,SSG,LPAY,PAYCO)
                      </div>
                    </td>
                    <td>일반</td>
                    <td>3.0%</td>
                    <td>상기 신용카드와 동일</td>
                  </tr>
                    <tr>
                        <td class="adj_color">계좌이체</td>
                        <td></td>
                        <td>2%(최저 200원)</td>
                        <td>2%(최저 200원)</td>
                    </tr>
                    <tr>
                        <td class="adj_color">가상계좌</td>
                        <td>
                        <td>350원/건</td>
                        <td>350원/건</td>
                    </tr>
<!--                    <tr>
                        <td class="adj_color">카카오페이</td>
                        <td>
                        <td>3.4%</td>
                        <td>3.4%</td>
                    </tr>
                    <tr>
                        <td class="adj_color">네이버페이</td>
                        <td>
                        <td>3.4%</td>
                        <td>3.4%</td>
                    </tr>-->
                </table>
                <p class="ml-1 mt-1 text-caption red--text">
                    *영세/중소 사업자 여부는 국세청 및 여신금융협회 자료를 기준으로, 6개월 단위로 매출 등급 조정 및 우대수수료가 적용됩니다.<br/>
                    *최근 1년 내 신고된 매출이 기준을 충족하지 못하실 경우에는 소상공인 신용카드 혜택을 받으실 수 없습니다.<br/>
                    *해당되는 신용카드 수수료 혜택은 심사 완료 후 부터 받을 실 수 있습니다.
                </p>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        dialog:false
    })
}
</script>
<style>

.cs {
  width:700px;
  margin:0 auto;
}

.cs tr td{
    height:50px;
    font-weight:bold;
}

.cs tr td:first-child{
    width:280px;
}


.cs tr td:not(:first-child){
    color:#a87675;
}



.cs_mobile tr td{
    height:50px;
}

.cs_mobile tr td:first-child{
    width:90px;
}


.cs_mobile tr td:not(:first-child){
    color:#a87675;
}

.adjustment{
    width:1274px;
    margin:0 auto;
    border:1px solid #a87675;
    border-collapse: collapse;
    text-align: center;
    font-weight:bold;
}

.adjustment tr td{
    border:1px solid #a87675;
    height:60px;
}

.adjustment tr:not(:first-child) td{
    height:100px;
}

.adjustment_mobile{
    margin:0 auto;
    border:1px solid #a87675;
    border-collapse: collapse;
    text-align: center;
    font-weight:bold;
    font-size:11pt;
}

.adjustment_mobile tr td{
    border:1px solid #a87675;
    padding:2px;
    height:60px;
}

.adjustment_mobile tr:not(:first-child) td{
    height:100px;
}

.adj_color{
    background:#dabfbe;
}

.adjustment2{
    margin:0 auto;
    border:1px solid #a87675;
    border-collapse: collapse;
    text-align: center;
    font-weight:bold;
}

.adjustment2 tr td{
    border:1px solid #a87675;
    height:60px;
}

.adjustment2 tr:not(:first-child) td{
    height:60px;
}

.adj_color{
    background:#dabfbe;
}

</style>