<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet v-if="$vuetify.breakpoint.mdAndUp" style="padding-top:100px; padding-bottom:300px;">
            <v-sheet width="1274" class="d-flex mx-auto">
              <v-btn tile depressed left :to="'/main/cs?type=notice'" color="#a87675" dark large class="px-7 mr-4">이전</v-btn>
            </v-sheet>
            <v-sheet width="1274" class="mt-10 mx-auto">
                <p class="text-h4 font-weight-bold">
                    공지사항
                </p>
                <v-divider color="#9F6564"></v-divider>
                <v-sheet>
                    <v-sheet class="mt-5 ml-4 d-flex">
                        <p class="text-h6"><v-icon left style="margin-bottom:6px;">mdi-alarm-light-outline</v-icon>{{selected.title}}</p>
                        <v-spacer></v-spacer>
                        <p class="text-subtitle-1 grey--text text--darken-2 mr-6 font-weight-bold">{{selected.writer}}</p>
                        <p class="text-subtitle-1 grey--text text--darken-2 mr-6">{{selected.createdAt}}</p>
                        <p v-if="selected.readCount" class="text-subtitle-1 grey--text text--darken-2 mr-6">조회수 {{selected.readCount}}</p>
                    </v-sheet>
                    <v-divider color="#9F6564"></v-divider>
                    <v-sheet class="pa-6 overflow-x-auto" min-height="500"  width="100%">
                      <div v-html="selected.content">
                      </div>
                    </v-sheet>
                </v-sheet>
                <v-divider color="#9F6564"></v-divider>
            </v-sheet>
        </v-sheet>

        <!-- 모바일 -->
        <v-sheet v-if="!$vuetify.breakpoint.mdAndUp" class="pt-10 pb-16 px-4">
            <v-sheet class="d-flex">
                <v-sheet class="d-flex">
                  <v-btn tile depressed left :to="'/main/cs?type=notice'" color="#a87675" dark large class="px-7 mr-4">이전</v-btn>
                </v-sheet>
            </v-sheet>
            <v-sheet class="mt-10 mx-auto">
                <p class="text-h5 font-weight-bold">
                    공지사항
                </p>
                <v-divider color="#9F6564"></v-divider>
                <v-sheet>
                    <v-sheet class="ml-4 d-flex">
                        <p class="mt-5 text-subtitle-1"><v-icon left style="margin-bottom:6px;">mdi-alarm-light-outline</v-icon>{{selected.title}}</p>
                        <v-spacer></v-spacer>
                        <v-sheet>
                            <v-sheet class="d-flex" style="margin-top:14px;">
                                <p class="text-caption grey--text text--darken-2 mr-2 mb-0 font-weight-bold">{{selected.writer}}</p>
                                <p v-if="selected.readCount" class="text-caption grey--text text--darken-2 mr-2 mb-0">│ 조회수 {{selected.readCount}}</p>
                            </v-sheet>
                            <p class="text-caption grey--text text--darken-2 mr-2 mb-0">{{selected.createdAt}}</p>
                        </v-sheet>
                    </v-sheet>
                    <v-divider color="#9F6564"></v-divider>
                    <v-sheet class="pa-6 overflow-x-auto" min-height="500" width="100%">
                      <div v-html="selected.content">
                      </div>
                    </v-sheet>
                </v-sheet>
                <v-divider color="#9F6564"></v-divider>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>

import {isEmpty} from "@/lib/CommUtil";
const {BoardApi} = require("@/api/BoardApi");

export default {
    data: () => ({
        board_type:"notice",

        items: [
            {id:10, title:'공지사항 10', writer:'운영자', content:'내용1', created: '2021년 12월 31일 오전 11:35', view_count:50},
            {id:9, title:'공지사항 9', writer:'운영자', content:'내용2', created: '2021년 12월 31일 오전 11:35', view_count:234},
            {id:8, title:'공지사항 8', writer:'운영자', content:'내용3', created: '2021년 12월 31일 오전 11:35', view_count:2342},
            {id:7, title:'공지사항 7', writer:'운영자', content:'내용4', created: '2021년 12월 31일 오전 11:35', view_count:32},
            {id:6, title:'공지사항 6', writer:'운영자', content:'내용5', created: '2021년 12월 31일 오전 11:35', view_count:30},
            {id:5, title:'공지사항 5', writer:'운영자', content:'내용6', created: '2021년 12월 31일 오전 11:35', view_count:60},
            {id:4, title:'공지사항 4', writer:'운영자', content:'내용7', created: '2021년 12월 31일 오전 11:35', view_count:57},
            {id:3, title:'공지사항 3', writer:'운영자', content:'내용8', created: '2021년 12월 31일 오전 11:35', view_count:11},
            {id:2, title:'공지사항 2', writer:'운영자', content:'내용9', created: '2021년 12월 31일 오전 11:35', view_count:1},
            {id:1, title:'공지사항 1', writer:'운영자', content:'내용10', created: '2021년 12월 31일 오전 11:35', view_count:0},
        ],

        selected: {
          seq:'',
          title:'',
          content:'',
          created:'',
          writer:'운영자',
        }
    }),
  created() {
      if(!isEmpty(this.$route.query.id)){
        BoardApi.getAdminNoticePost(this.$route.query.id).then(res =>{
          console.log(res)
          this.selected=res
        })
      }

  },
  mounted(){
        if(this.$route.query.type=="notice"){
            this.items=this.notice_items
            this.board_type="notice"
        }else if(this.$route.query.type=="qna"){
            this.items=this.qna_items
            this.board_type="qna"
        }

        let item = this.items.filter(e => e.id==this.$route.query.id)

        this.selected = item[0];
    },

    methods: {
        goToRead(item){
            this.$router.push('/page/cs_read?type='+this.board_type+'&id='+item.id)
        }
    }
}
</script>
<style>
.cs_table{
    max-width:1274px;
}

.cs_table thead th {
    height:50px !important;
    font-size: 16px !important;
}

.cs_table tbody td {
    height:50px !important;
    font-size: 16px !important;
}

.cs_table_mobile{
    max-width:1274px;
}

.cs_table_mobile thead th {
    font-size: 12px !important;
}

.cs_table_mobile tbody td {
    font-size: 12px !important;
}
</style>