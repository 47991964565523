<template>
  <div>
    <div v-if="boardType ==='notice'">
      <p>
        공지 게시판입니다.
      </p>
    </div>
    <div v-if="boardType ==='qna'">
      <p>
        문의 게시판입니다.<br>
        다양한 문의를 해주세요. 버그/오류 리포트나 필요 기능에 대해서 요청을 해주셔도 됩니다. 개발 부서에서 적극 검토하여 빠르게 대응해 드립니다.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "cs_info",
  props:{
    boardType:String
  }
}
</script>

<style scoped>

</style>