<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet v-if="$vuetify.breakpoint.mdAndUp" style="padding-top:100px; padding-bottom:300px;">
            <v-sheet width="1274" class="mt-10 mx-auto">
              <v-sheet class="d-flex mx-auto" style="position: relative">
                <p class="text-h4 font-weight-bold">
                  <span>Q&A</span>
                  <span class="ml-4">글수정</span>
                </p>
                <div style="position: absolute;right: 0px">
                  <v-btn tile depressed left color="#a87675" dark large class="px-7 ml-4"
                         :loading="loading"
                         @click="save"
                  >저장</v-btn>
                  <v-btn tile outlined left large class="px-7 ml-4"
                         @click="cancel"
                  ><span style="font-weight: bolder">취소</span></v-btn>
                </div>
              </v-sheet>
                <v-divider color="#9F6564"></v-divider>
                <v-sheet>
                    <v-sheet class="mt-5 ml-4 d-flex">
                        <p class="text-h6"><v-icon left style="margin-bottom:6px;">mdi-alarm-light-outline</v-icon>
                          <span class="ml-4">{{selected.title}}</span>
                        </p>
                        <v-spacer></v-spacer>
                        <p class="text-subtitle-1 grey--text text--darken-2 mr-8 font-weight-bold">{{selected.writer}}</p>
                      <p class="text-subtitle-1 grey--text text--darken-2 mr-8 font-weight-bold">
                        <span v-if="selected.replied === true "> 답변</span>
                        <span v-else style="color: #9f6564">미답변</span>
                      </p>
                        <p class="text-subtitle-1 grey--text text--darken-2 mr-6">{{selected.createdAt}}</p>
                        <p v-if="selected.view_count" class="text-subtitle-1 grey--text text--darken-2 mr-6">조회수 {{selected.view_count}}</p>
                    </v-sheet>
                    <v-divider color="#9F6564"></v-divider>
                    <v-sheet class="pa-6" min-height="500">
                      <v-textarea
                          filled
                          outlined
                          auto-grow
                          rows="5"
                          row-height="50"
                          v-model="selected.content"
                      ></v-textarea>
                    </v-sheet>
                </v-sheet>
                <v-divider color="#9F6564"></v-divider>
            </v-sheet>
        </v-sheet>

        <!-- 모바일 -->
        <v-sheet v-if="!$vuetify.breakpoint.mdAndUp" class="pt-10 pb-16 px-4">
          <v-sheet class="mt-10 mx-auto">
            <v-sheet class="d-flex mx-auto" style="position: relative">
              <p class="text-h5 font-weight-bold">
                <span>Q&A</span>
                <span class="ml-4">글수정</span>
              </p>
              <div style="position: absolute;right: 0px">
                <v-btn tile depressed left color="#a87675" dark large class="px-7 ml-4"
                       :loading="loading"
                       @click="save"
                >저장</v-btn>
                <v-btn tile outlined left large class="px-7 ml-4"
                       @click="cancel"
                ><span style="font-weight: bolder">취소</span></v-btn>
              </div>
            </v-sheet>
            <v-divider color="#9F6564"></v-divider>
            <v-sheet>
              <v-sheet class="mt-5 ml-4 d-flex">
                <p class="text-h6 text-subtitle-1"><v-icon left style="margin-bottom:6px;">mdi-alarm-light-outline</v-icon>
                  <span class="ml-4">{{selected.title}}</span>
                </p>
                <v-spacer></v-spacer>
                <div>
                  <p class=" text-caption text-subtitle-1 grey--text text--darken-2 mr-8 font-weight-bold">{{selected.writer}}</p>
                  <p class="text-caption text-subtitle-1 grey--text text--darken-2 mr-8 font-weight-bold">
                    <span v-if="selected.replied === true "> 답변</span>
                    <span v-else style="color: #9f6564">미답변</span>
                  </p>
                  <p class="text-caption text-subtitle-1 grey--text text--darken-2 mr-6">{{selected.createdAt}}</p>
                  <p v-if="selected.view_count" class="text-subtitle-1 grey--text text--darken-2 mr-6">조회수 {{selected.view_count}}</p>
                </div>
              </v-sheet>
              <v-divider color="#9F6564"></v-divider>
              <v-sheet class="pa-6" min-height="500">
                <v-textarea
                    filled
                    class="body-2"
                    outlined
                    auto-grow
                    rows="5"
                    row-height="50"
                    v-model="selected.content"
                ></v-textarea>
              </v-sheet>
            </v-sheet>
            <v-divider color="#9F6564"></v-divider>
          </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>

import {BoardApi} from '../../api/BoardApi'
import {isEmpty} from '../../lib/CommUtil'


export default {
    data: () => ({
      selected: {},
      loading:false,
      authenticated:false,
    }),
    mounted(){
      this.authenticated = this.$store.getters.isAuthenticated
      const item = this.$store.getters.getQnAItem
      if(item === null){
        if(this.authenticated === true){
          BoardApi.getMemberQuestion(this.$route.query.id).then( res =>{
            if(res.resCode ==='0000'){
              console.log('getMemberQuestion',res)
              this.selected=res.data
            }else{
              alert(res.msg)
            }
          }).catch((data)=>{
            console.log('err',data)
            if(data.data.resCode ==='0412'){
              alert('세션만료입니다. 다시 인증해야 합니다')
              this.$router.push('/main/cs?type=qna')
              return
            }
            if(!isEmpty(data.data.msg)){
              alert(data.data.msg)
            }else{
              alert('글읽기 요청이 실패하였습니다')
            }
          })
          return
        }
        BoardApi.getNonMemberQuestion({
          questionId:this.$route.query.id,
          key:this.$route.query.key,
        }).then( res =>{
          if(res.resCode ==='0000'){
            console.log('getNonMemberQuestion',res)
            this.selected=res.data
          }else{
            alert(res.msg)
          }
        }).catch((data)=>{
          console.log('err',data)
          if(data.data.resCode ==='0412'){
            alert('세션만료입니다. 다시 인증해야 합니다')
            this.$router.push('/main/cs?type=qna')
            return
          }
          if(!isEmpty(data.data.msg)){
            alert(data.data.msg)
          }else{
            alert('글읽기 요청이 실패하였습니다')
          }
        })
      }else{
        this.selected=item
      }
    },

    methods: {
      cancel(){
        if(this.$route.query.authenticated === '1'){
          this.$router.push({name:'qa-read', query:{
              id:this.$route.query.id,
              authenticated:'1',
            }})
          return
        }
        this.$router.push({name:'qa-read', query:{
            id:this.$route.query.id,
            key:this.$route.query.key,
          }})
      },
      memberRequest(){
        BoardApi.updateMemberQuestion(
          this.$route.query.id,this.selected.content
        ).then(() =>{
          this.loading=false
          this.$router.push({name:'qa-read', query:{
              id:this.$route.query.id,
              authenticated:'1',
            }})
        }).catch((data)=>{
          this.loading=false
          console.log('err',data)
          if(data.data.resCode ==='0412'){
            alert('세션만료입니다. 다시 인증해야 합니다')
            this.$router.push('/main/cs?type=qna')
            return
          }
          if(!isEmpty(data.data.msg)){
            alert(data.data.msg)
          }else{
            alert('글읽기 요청이 실패하였습니다')
          }
        })
      },
      save(){
        if(this.authenticated === true){
          this.memberRequest()
          return
        }
        this.loading=true
        console.log('save',this.selected)
        BoardApi.updateNonMemberQuestion({
          questionId:this.$route.query.id,
          key:this.$route.query.key,
          content:this.selected.content
        }).then(() =>{
          this.loading=false
          this.$router.push({name:'qa-read', query:{
              id:this.$route.query.id,
              key:this.$route.query.key,
            }})
        }).catch((data)=>{
          this.loading=false
          console.log('err',data)
          if(data.data.resCode ==='0412'){
            alert('세션만료입니다. 다시 인증해야 합니다')
            this.$router.push('/main/cs?type=qna')
            return
          }
          if(!isEmpty(data.data.msg)){
            alert(data.data.msg)
          }else{
            alert('글읽기 요청이 실패하였습니다')
          }
        })
      }
    }
}
</script>
<style>
.cs_table{
    max-width:1274px;
}

.cs_table thead th {
    height:50px !important;
    font-size: 16px !important;
}

.cs_table tbody td {
    height:50px !important;
    font-size: 16px !important;
}

.cs_table_mobile{
    max-width:1274px;
}

.cs_table_mobile thead th {
    font-size: 12px !important;
}

.cs_table_mobile tbody td {
    font-size: 12px !important;
}
</style>