<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet v-if="$vuetify.breakpoint.mdAndUp">
          <p class="text-h5 text-center mt-12">
            <font class="font-weight-bold">현재 개발 중인 기능입니다.</font><br/>

          </p>
        </v-sheet>

        <!-- 모바일 -->
        <v-sheet v-if="!$vuetify.breakpoint.mdAndUp">
            <v-sheet color="transparent" class="my-16 text-center">
                <p class="text-h4 font-weight-bold">나만의 무료<br/>반응형 쇼핑몰</p>
                <p class="text-subtitle-1">
                    전문 지식 없이도 쉽고 빠르게<br/>
                    <font class="font-weight-bold">나만의 반응형 쇼핑몰</font>을 별도 비용 없이<br/>직접 만들 수 있습니다
                </p>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>

import MainFunctionView from "../main/MainFunctionView";
import MainFunctionView2 from "@/views/page/main/MainFunctionView2";

export default {
  components:{
    MainFunctionView2,
    MainFunctionView
  },
  data(){
    return {
      tabIndex:0
    }
  }
}
</script>
<style>
.test .v-btn{
    background:white;
}

.test .v-btn .v-btn__content .v-icon{
    color: #a87675;
}

.v-carousel__controls__item{
    color: #a87675 !important
}
</style>