<template>
  <v-sheet>
    <!-- PC -->
    <v-sheet v-if="$vuetify.breakpoint.mdAndUp" style="padding-top:100px; padding-bottom:300px;">
      <v-sheet width="1274" class="mt-10 mx-auto">
        <v-sheet width="1274" class="mt-10 mx-auto">
          <p class="text-h4 font-weight-bold">
            글쓰기(Q&A)
          </p>
          <v-divider color="#9F6564"></v-divider>
          <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;"  v-if="isAuthenticated === false">
            <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">성명/상호명<font class="red--text">*</font></font>
            <v-text-field
                class="pt-5 px-2"
                placeholder="성명 또는 상호명을 입력해주세요"
                dense
                color="brown"
                style="background:#ececec !important;"
                required
                v-model="writer"
                @change="$v.writer.$touch()"
                @blur="$v.writer.$touch()"
                :error-messages="writer_Errors"
            ></v-text-field>
          </v-sheet>
          <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;"  v-else>
            <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">성명/상호명<font class="red--text">*</font></font>
            {{sellerName}}
          </v-sheet>
          <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
            <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">제목<font class="red--text">*</font></font>
            <v-text-field
                class="pt-5 px-2"
                placeholder="제목을 입력해주세요"
                dense
                color="brown"
                style="background:#ececec !important;"
                :error-messages="title_Errors"
                @change="$v.title.$touch()"
                @blur="$v.title.$touch()"
                v-model="title"
                required
            ></v-text-field>
          </v-sheet>
          <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
            <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">내용<font class="red--text">*</font></font>
            <v-textarea
                class="pt-5 px-2"
                filled
                auto-grow
                rows="5"
                row-height="50"
                style="background:#ececec !important;"
                :error-messages="content_Errors"
                @change="$v.content.$touch()"
                @blur="$v.content.$touch()"
                v-model="content"
            ></v-textarea>
          </v-sheet>
          <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;" v-if="isAuthenticated === false">
            <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">비밀번호<font class="red--text">*</font></font>
            <v-text-field
                class="pt-5 px-2"
                placeholder="게시물을 확인/수정/삭제를 위한 필수 입력 입니다"
                dense
                color="brown"
                style="background:#ececec !important;"
                required
                type="password"
                :error-messages="password_Errors"
                @change="$v.password.$touch()"
                @blur="$v.password.$touch()"
                v-model="password"
            ></v-text-field>
          </v-sheet>
          <v-sheet width="1274" class="mt-10 mx-auto align-center text-center">
              <v-btn tile depressed color="#a87675" dark x-large class="px-7 mr-4" style="width: 150px"
                     @click="save"
                     :loading="loading"
              >저장</v-btn>
              <v-btn tile outlined x-large class="px-9" style="width: 150px;font-weight: bolder"
                     to="/main/cs?type=qna"
              >취소</v-btn>
          </v-sheet>
        </v-sheet>
      </v-sheet>
    </v-sheet>
    <!-- 모바일 -->
    <v-sheet v-if="!$vuetify.breakpoint.mdAndUp">
      <v-sheet class="mt-10 mx-auto">
        <v-sheet class="mt-10 mx-auto">
          <p class="text-h5 font-weight-bold">
            글쓰기(Q&A)
          </p>
          <v-divider color="#9F6564"></v-divider>
          <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;"  v-if="isAuthenticated === false">
            <font class="text-caption font-weight-bold text-center" style="font-size:14px; width:120px;">성명/상호명<font class="red--text">*</font></font>
            <v-text-field
                class="pt-5 px-2"
                placeholder="성명 또는 상호명을 입력해주세요"
                dense
                color="brown"
                style="background:#ececec !important;"
                required
                v-model="writer"
                @change="$v.writer.$touch()"
                @blur="$v.writer.$touch()"
                :error-messages="writer_Errors"
            ></v-text-field>
          </v-sheet>
          <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;"  v-else>
            <font class="text-caption font-weight-bold text-center" style="font-size:14px; width:120px;">성명/상호명<font class="red--text">*</font></font>
            <span class="text-caption">{{sellerName}}</span>
          </v-sheet>
          <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
            <font class="text-caption font-weight-bold text-center" style="font-size:14px; width:120px;">제목<font class="red--text">*</font></font>
            <v-text-field
                class="pt-5 px-2"
                placeholder="제목을 입력해주세요"
                dense
                color="brown"
                style="background:#ececec !important;"
                :error-messages="title_Errors"
                @change="$v.title.$touch()"
                @blur="$v.title.$touch()"
                v-model="title"
                required
            ></v-text-field>
          </v-sheet>
          <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
            <font class="text-caption font-weight-bold text-center" style="font-size:14px; width:120px;">내용<font class="red--text">*</font></font>
            <v-textarea
                class="pt-5 px-2 body-2"
                filled
                auto-grow
                rows="5"
                row-height="50"
                style="background:#ececec !important;"
                :error-messages="content_Errors"
                @change="$v.content.$touch()"
                @blur="$v.content.$touch()"
                v-model="content"
            ></v-textarea>
          </v-sheet>
          <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;" v-if="isAuthenticated === false">
            <font class="text-caption font-weight-bold text-center" style="font-size:14px; width:120px;">비밀번호<font class="red--text">*</font></font>
            <v-text-field
                class="pt-5 px-2"
                placeholder="게시물을 확인/수정/삭제를 위한 필수 입력 입니다"
                dense
                color="brown"
                style="background:#ececec !important;"
                required
                type="password"
                :error-messages="password_Errors"
                @change="$v.password.$touch()"
                @blur="$v.password.$touch()"
                v-model="password"
            ></v-text-field>
          </v-sheet>
          <v-sheet class="mt-10 mx-auto align-center text-center">
            <v-btn tile depressed color="#a87675" dark x-large class="px-7 mr-4" style="width: 150px"
                   @click="save"
                   :loading="loading"
            >저장</v-btn>
            <v-btn tile outlined x-large class="px-9" style="width: 150px;font-weight: bolder"
                   to="/main/cs?type=qna"
            >취소</v-btn>
          </v-sheet>
        </v-sheet>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import {BoardApi} from '../../api/BoardApi'
import {isEmpty} from "@/lib/CommUtil";

export default {
  name: "qa_post",
  mixins: [validationMixin],

  validations: {
    writer: {
      required,
      maxLength: maxLength(20),
    },
    title: {
      required,
      maxLength: maxLength(128),
    },
    content: {
      required,
    },
    password: {
      required,
      minLength: minLength(6),
    },

  },
  mounted() {
    this.authenticated = this.$store.getters.isAuthenticated
  },
  computed: {
    isAuthenticated(){
      return this.$store.getters.isAuthenticated
    },
    sellerName(){
      const myInfo =  this.$store.getters.getMyInfo
      if(isEmpty(myInfo)){
        return ''
      }
      if(isEmpty(myInfo.sellerName)){
        return ''
      }
      return myInfo.sellerName
    },
    writer_Errors () {
      const errors = []
      if (!this.$v.writer.$dirty) return errors
      !this.$v.writer.required && errors.push('상호명 혹은 성명을 입력해 주세요')
      !this.$v.writer.maxLength && errors.push('최대 20자 이내이여야합니다')
      return errors
    },
    password_Errors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('패스워드를 입력해주세요')
      !this.$v.password.minLength && errors.push('패스워드는 최소 6자 이상이여야합니다')
      return errors
    },
    title_Errors () {
      const errors = []
      if (!this.$v.title.$dirty) return errors
      !this.$v.title.required && errors.push('제목을 입력해주세요')
      !this.$v.title.maxLength && errors.push('최대 128자 이내이여야합니다')
      return errors
    },
    content_Errors () {
      const errors = []
      if (!this.$v.content.$dirty) return errors
      !this.$v.content.required && errors.push('내용을 입력해주세요')
      return errors
    },
  },
  data(){
    return {
      writer:'',
      title:'',
      content:'',
      password:'',
      loading:false,
      authenticated:false,
    }
  },
  methods:{
    memberRequest(){
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('memberRequest',this.$v)
        if (this.$v.title.$invalid || this.$v.content.$invalid) {
          alert('비어있는 입력란이 있습니다.')
          return
        }
      }
      const request ={
        title:this.title,
        content:this.content,
      }
      this.loading=true
      BoardApi.postMemberQuestion(request).then(()=>{
        alert('등록성공입니다')
        this.loading=false
        this.$router.push('/main/cs?type=qna')
      }).catch(()=>{
        alert('등록 실패입니다. 문제가 지속되면 관리자에게 문의해 주세요')
        this.loading=false
      })
    },
    save(){
      if(this.authenticated === true){
        this.memberRequest()
        return
      }
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        alert('비어있는 입력란이 있습니다.')
        return
      }
      const request ={
        name:this.writer,
        title:this.title,
        content:this.content,
        password:this.password
      }
      this.loading=true
      BoardApi.postNonMemberQuestion(request).then(()=>{
        alert('등록성공입니다')
        this.loading=false
        this.$router.push('/main/cs?type=qna')
      }).catch(()=>{
        alert('등록 실패입니다. 문제가 지속되면 관리자에게 문의해 주세요')
        this.loading=false
      })
    },

  }

}
</script>

<style scoped>

</style>