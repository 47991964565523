import axios from 'axios'
import * as qs from "qs";
import {tokenApi} from "@/api/CoreApi";

const DOMAIN = process.env.VUE_APP_API_HOST


const Domain = axios.create({
    baseURL: DOMAIN,
    headers: {
        get: {
            "Content-Type": "*;charset=UTF-8"
        },
        post: {        // can be common or any other method
            "Content-Type": "text/plain;charset=UTF-8"
        },
        'Accept': 'application/json;charset=UTF-8'
    }
})



export const BoardApi = {
    getNonMemberQuestions(request){
        const params = {
            ... request
        }
        return Domain.get(DOMAIN + '/wsp/api/v1/wspmain/nonmember/questions',{ params , paramsSerializer: params => {
                return qs.stringify(params)
            }}).then( result =>{
            const res = result.data;
            if( res.resCode === '0000'){
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    postNonMemberQuestion(request){
        return Domain.post(DOMAIN + '/wsp/api/v1/wspmain/nonmember/questions',request).then( result =>{
            const res = result.data;
            if( res.resCode === '0000'){
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    postNonMemberQuestionReply(request){
        const params = {
            key:request.key,
            content: request.content,
            parentId: request.replyId,
        }
        return Domain.post( '/wsp/api/v1/wspmain/nonmember/questions/'+request.questionId + '/reply',params).then( result =>{
            const res = result.data;
            if( res.resCode === '0000'){
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    getNonMemberQuestion(request){
        const params = {
            key:request.key
        }
        return Domain.get(DOMAIN + '/wsp/api/v1/wspmain/nonmember/questions/'+request.questionId,{ params , paramsSerializer: params => {
                return qs.stringify(params)
            }}).then( result =>{
            return result.data;
        }).catch( result => {
            throw result.response
        })
    },
    updateNonMemberQuestion(request){
        return Domain.put(DOMAIN + '/wsp/api/v1/wspmain/nonmember/questions/'+request.questionId,request).then( result =>{
            return result.data;
        }).catch( result => {
            throw result.response
        })
    },
    deleteNonMemberQuestion(request){
        return Domain.delete(DOMAIN + '/wsp/api/v1/wspmain/nonmember/questions/'+request.questionId + '/'+request.key).then( result =>{
            return result.data;
        }).catch( result => {
            throw result.response
        })
    },
    getAdminNotices(){
        return Domain.get( '/wsp/api/v1/wspmain/admin/board/notices',).then( result =>{
            const res = result.data;
            if( res.resCode === '0000'){
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    getAdminNoticesByPage(request){
        const params = {
            ... request
        }
        return Domain.get( '/wsp/api/v1/wspmain/admin/board/notices',{ params , paramsSerializer: params => {
                return qs.stringify(params)
            }}).then( result =>{
            const res = result.data;
            if( res.resCode === '0000'){
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    getAdminNoticePost(id){
        return Domain.get( '/wsp/api/v1/wspmain/admin/board/notices/'+id).then( result =>{
            const res = result.data;
            if( res.resCode === '0000'){
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    postNonMemberMigrationQuestion(request){
        return Domain.post( '/wsp/api/v1/wspmain/nonmember/questions/migration',request).then( result =>{
            const res = result.data;
            if( res.resCode === '0000'){
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    postMemberQuestion(request){
        return tokenApi.post( '/wsp/api/v1/wspmain/member/questions',request).then( result =>{
            const res = result.data;
            if( res.resCode === '0000'){
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    getMemberQuestion(questionId){
        return tokenApi.get( '/wsp/api/v1/wspmain/member/questions/'+questionId).then( result =>{
            return result.data;
        }).catch( result => {
            throw result.response
        })
    },
    updateMemberQuestion(questionId,content){
        const config = {
            headers: {
                'Content-Type': 'text/plain'
            }
        };
        return tokenApi.put( '/wsp/api/v1/wspmain/member/questions/'+questionId,content,config).then( result =>{
            return result.data;
        }).catch( result => {
            throw result.response
        })
    },
    deleteMemberQuestion(questionId){
        return tokenApi.delete( '/wsp/api/v1/wspmain/member/questions/'+questionId).then( result =>{
            return result.data;
        }).catch( result => {
            throw result.response
        })
    },
}
