<template>
  <div>
    <v-sheet>
      <v-sheet class="ml-4 d-flex" style="position: relative">
        <p class="text-h6">
          <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 15l-6 6-1.42-1.42L15.17 16H4V4h2v10h9.17l-3.59-3.58L13 9l6 6z"/>
          </svg>
          <span style="margin-left: 8px">답변</span>
        </p>
        <div style="position: absolute;right: 0px">
          <span class="text-subtitle-1 grey--text text--darken-2 mr-8 font-weight-bold" v-if="reply.adminReply === true">
            관리자
          </span>
          <span v-else class="text-subtitle-1 grey--text text--darken-2 mr-8 font-weight-bold">
            {{reply.writer}}
          </span>
          <span class="text-subtitle-1 grey&#45;&#45;text text&#45;&#45;darken-2 mr-6">{{reply.registeredAt}}</span>
          <v-btn tile depressed left color="#a87675" dark large class="px-7 ml-4" v-if="reply.adminReply ===true && onReply===false"
                 @click="replyPost"
          >답변</v-btn>
        </div>
      </v-sheet>
      <v-sheet class="pa-6" min-height="200">
        <v-textarea
            v-if="onModify === false"
            auto-grow
            rows="4"
            row-height="50"
            readonly
            v-model="reply.content"
            color="#FFFFFF"
        ></v-textarea>
        <v-textarea
            v-else
            class="pt-5 px-2"
            filled
            auto-grow
            rows="5"
            row-height="50"
            style="background:#ececec !important;"
            v-model="reply.content"
        ></v-textarea>
        <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;"  v-if="onReply === true">
          <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">답변<font class="red--text">*</font></font>
          <div style="width:100%;">
            <v-textarea
                class="pt-5 px-2"
                filled
                auto-grow
                rows="5"
                row-height="50"
                style="background:#ececec !important;"
                v-model="replyContent"
            ></v-textarea>
            <div class="text-center">
              <v-btn tile depressed left color="#a87675" dark large class="px-7 ml-4"
                     @click="saveReply"
              >저장</v-btn>
              <v-btn tile depressed left color="#a87675" dark large class="px-7 ml-4"
                     @click="onReply=false"
              >취소</v-btn>
            </div>
          </div>
        </v-sheet>
        <div v-if="hasReply === true"
             style="padding-left: 15px"
        >
          <QnAReply
              style="margin-left: 20px"
              v-for="(reply,i) in reply.nonMemberBoardReplies" :key="i"
              v-bind:reply="reply"
          />
        </div>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>

import {isEmpty} from "@/lib/CommUtil";
import {BoardApi} from '../../api/BoardApi'

export default {
  name: "QnAReply",
  props:{
    reply:Object
  },
  created() {
    console.log('QnAReply created')
  },
  computed:{
    hasReply(){
      if(isEmpty(this.reply.nonMemberBoardReplies)){
        return false
      }
      return this.reply.nonMemberBoardReplies.length > 0
    }
  },
  data(){
    return {
      onReply:false,
      onModify:false,
      replyContent: '',
    }
  },
  methods:{
    replyPost(){
      this.onReply=true
    },
    saveReply(){
      const request = {
        content:this.replyContent,
        replyId: this.reply.id,
        questionId:this.$route.query.id,
        key:this.$route.query.key,
      }
      BoardApi.postNonMemberQuestionReply(request).then( () =>{

      }).catch((data)=>{
        console.log('err',data)
        if(data.data.resCode ==='0412'){
          alert('세션만료입니다. 다시 인증해야 합니다')
          this.$router.push('/main/cs?type=qna')
          return
        }
        if(!isEmpty(data.data.msg)){
          alert(data.data.msg)
        }else{
          alert('글읽기 요청이 실패하였습니다')
        }
      })
    },
    saveModify(){
      const request = {
        content:this.reply.content,
        replyId: this.reply.id
      }
      BoardApi.updateQnAPostReply(this.$route.query.id,request).then( () =>{
        this.onModify=false
      }).catch((data)=>{
        this.onModify=false
        console.log('err',data)
        if(!isEmpty(data.data.msg)){
          alert(data.data.msg)
        }else{
          alert('글읽기 요청이 실패하였습니다')
        }
      })
    },
  }
}
</script>

<style scoped>

</style>