<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet v-if="$vuetify.breakpoint.mdAndUp">
          <MainFunctionView/>
          <MainFunctionView2/>
          <v-sheet color="transparent" height="300" style="padding-top:250px;" class="text-center">
              <p class="text-h3 font-weight-bold">정말 편리하고 쉬운 기능</p>
          </v-sheet>
          <!-- 상품 관리 설명
          -->
          <v-sheet color="transparent" style="margin-bottom:200px;" width="1100" class="d-flex mx-auto mt-10">
            <v-carousel
                hide-delimiter-background
                class="test"
            >
              <v-carousel-item class="mx-auto">
                <v-img width="556" contain :src="require('@/assets/introduce/item2.png')"></v-img>
              </v-carousel-item>

              <v-carousel-item class="mx-auto">
                <v-img color="transparent" width="556" height="556" contain :src="require('@/assets/introduce/delivery1.png')"></v-img>
              </v-carousel-item>

              <v-carousel-item class="mx-auto">
                <v-img color="transparent" width="556" :src="require('@/assets/introduce/option1.png')" >
                </v-img>
              </v-carousel-item>
              <v-carousel-item class="mx-auto">
                <v-img color="transparent" width="556" :src="require('@/assets/introduce/option2.png')" >
                </v-img>
              </v-carousel-item>
            </v-carousel>
            <div class="pl-10 pt-5">
              <p class="text-h4 font-weight-bold text-center">초고속 상품 등록</p>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">간단한 상품 등록</p>
                  <p class="mb-0 text-subtitle-2">필수사항 5가지만 입력하면 상품등록이 완료됩니다.상품 복사, 상품 일괄 등록으로 편하게 상품을 등록할 수 있습니다.</p>
                </v-card>
              </v-sheet>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">편리하고 다양한 배송비 정책</p>
                  <p class="mb-0 text-subtitle-2">배송설정에서 묶음배송, 배송비 설정을 하면 상품등록 할 때 기본정책으로 자동 설정 됩니다.</p>
                </v-card>
              </v-sheet>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">상세한 옵션</p>
                  <p class="mb-0 text-subtitle-2">색상, 사이즈에 대한 옵션 등록을 할 경우 재고 설정이 가능하고 직접입력 요청사항이나 추가상품 등록도 가능합니다.</p>
                </v-card>
              </v-sheet>
            </div>
          </v-sheet>
          <v-sheet color="transparent" style="margin-bottom:200px;" width="1100" class="d-flex mx-auto">
            <<v-carousel
              hide-delimiter-background
              class="test"
          >
            <v-carousel-item class="mx-auto">
              <v-img width="556" height="556" :src="require('@/assets/introduce/order1.png')"></v-img>
            </v-carousel-item>

            <v-carousel-item class="mx-auto">
              <v-img color="transparent" width="556" height="556" contain :src="require('@/assets/introduce/delivery1.png')"></v-img>
            </v-carousel-item>

            <v-carousel-item class="mx-auto">
              <v-img color="transparent" width="556" :src="require('@/assets/introduce/option1.png')" >
              </v-img>
            </v-carousel-item>
            <v-carousel-item class="mx-auto">
              <v-img color="transparent" width="556" :src="require('@/assets/introduce/option2.png')" >
              </v-img>
            </v-carousel-item>
          </v-carousel>
            <div class="pl-10 pt-5">
              <p class="mt-10 text-h4 font-weight-bold text-center">편리한 주문관리</p>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">주문상품 항목별 확인 및 현황파악</p>
                  <p class="mb-0 text-subtitle-2">전체, 입금확인 중, 신규주문 등 항목별로 정리된 리스트로 확인 가능하여 다음 프로세스로 진행하기 쉽습니다.</p>
                </v-card>
              </v-sheet>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">옵션 설정하여 엑셀 다운받기</p>
                  <p class="mb-0 text-subtitle-2">환경설정에서 원하는 엑셀옵션을 설정하면 주문관리에서 설정옵션으로 다운로드가 가능합니다.</p>
                </v-card>
              </v-sheet>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">상품별로 송장번호 일괄등록</p>
                  <p class="mb-0 text-subtitle-2">다운받은 엑셀에 송장번호를 입력하고 송장일괄등록을 통해 파일을 업로드하면 선택한 리스트의 송장등록이 완료됩니다.</p>
                </v-card>
              </v-sheet>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">주문상태 변경하기</p>
                  <p class="mb-0 text-subtitle-2">입금내역이나 결제완료 확인 후 주문상태를 변경하여 구매자가 처리상태를 확인할 수 있습니다.</p>
                </v-card>
              </v-sheet>
            </div>
          </v-sheet>
          <v-sheet color="transparent" style="margin-bottom:200px;" width="1100" class="d-flex mx-auto">
            <v-carousel
                hide-delimiter-background
                class="test"
            >
              <v-carousel-item class="mx-auto">
                <v-img width="556" height="556" :src="require('@/assets/introduce/settlement1.jpg')"></v-img>
              </v-carousel-item>

              <v-carousel-item class="mx-auto">
                <v-img color="transparent" width="556"  :src="require('@/assets/introduce/settlement2.jpg')"></v-img>
              </v-carousel-item>
            </v-carousel>
            <div class="pl-10">
              <p class="mt-10 text-h4 font-weight-bold text-center">보기편한 매출/통계</p>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">매출 리스트</p>
                  <p class="mb-0 text-subtitle-2">판매상품의 결제수단별/기간별 매출을 리스트로 확인가능하고 검색 또한 가능합니다.</p>
                </v-card>
              </v-sheet>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">일별/월별 통계</p>
                  <p class="mb-0 text-subtitle-2">주문 데이터를 수집 후 일별/월별로 구분해 통계자료를 확인하실 수 있습니다.</p>
                </v-card>
              </v-sheet>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">상품별 통계</p>
                  <p class="mb-0 text-subtitle-2">판매기간을 기준으로 상품의 최대/최소 판매 여부를 확인해 향후 상품 판매계획에 활용할 수 있습니다.</p>
                </v-card>
              </v-sheet>
            </div>
          </v-sheet>
          <v-sheet color="transparent" style="margin-bottom:200px;" width="1100" class="d-flex mx-auto">
            <v-carousel
                hide-delimiter-background
                class="test"
            >
              <v-carousel-item class="mx-auto">
                <v-img width="556" height="556" :src="require('@/assets/introduce/sms1.png')"></v-img>
              </v-carousel-item>

              <v-carousel-item class="mx-auto">
                <v-img height="556" contain :src="require('@/assets/introduce/sms2.png')"></v-img>
              </v-carousel-item>

              <v-carousel-item class="mx-auto">
                <v-img width="556"   contain :src="require('@/assets/introduce/sms3.png')" >
                </v-img>
              </v-carousel-item>
            </v-carousel>
            <div class="pl-10">
              <p class="text-h4 font-weight-bold text-center">자동메시지 관리기능</p>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">단문/장문 메시지</p>
                  <p class="mb-0 text-subtitle-2">어떤 종류든 저렴한 비용으로 구매자에게 메시지를 보낼 수 있습니다.</p>
                </v-card>
              </v-sheet>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">편리한 자동 발송</p>
                  <p class="mb-0 text-subtitle-2">
                    SMS발신번호 등록요청을 하고, 메시지 내용을 입력합니다.<br/>
                    상태 별 메시지는 무통장주문, 주문완료, 입금확인, 상품배송, 주문취소에 대한 <br/>
                    내용을 입력할 수 있고 기타 메시지 설정도 가능합니다.
                  </p>
                </v-card>
              </v-sheet>
              <v-sheet>
                <v-card flat class="text-center py-3 my-2 px-4" style="background:#dabfbe">
                  <p class="mb-1 text-h6 font-weight-bold">발송내역 조회</p>
                  <p class="mb-0 text-subtitle-2">SMS/알림톡이 오류 없이 발송됐는지 확인할 수 있도록 내역조회가 가능합니다.</p>
                </v-card>
              </v-sheet>
            </div>
          </v-sheet>
        </v-sheet>

        <!-- 모바일 -->
        <v-sheet v-if="!$vuetify.breakpoint.mdAndUp">
            <v-sheet color="transparent" class="my-16 text-center">
                <p class="text-h4 font-weight-bold">나만의 무료<br/>반응형 쇼핑몰</p>
                <p class="text-subtitle-1">
                    전문 지식 없이도 쉽고 빠르게<br/>
                    <font class="font-weight-bold">나만의 반응형 쇼핑몰</font>을 별도 비용 없이<br/>직접 만들 수 있습니다
                </p>
            </v-sheet>
            <v-sheet>
                <v-sheet>
                    <v-img contain :src="require('@/assets/introduce/1.png')"></v-img>
                </v-sheet>
                <v-sheet class="text-center mt-10">
                    <p class="text-h4 font-weight-bold">SNS 마켓 판매</p>
                    <p class="text-subtitle-1 px-4">
                        다양한 SNS에서 <font class="font-weight-bold">별도의 쇼핑몰을 만들지 않아도</font><br/>
                        주문서와 배송관리를 사용할 수 있는 결제 서비스입니다.<br/>
                        <font class="font-weight-bold">SNS마켓의 단점인 제한적 결제수단! 이제 주문서 링크를 SNS에 게시하세요.</font><br/>
                        카드는 물론 다양한 결제수단을 지원합니다.
                    </p>
                </v-sheet>
            </v-sheet>
            <v-sheet color="transparent" class="mt-16 text-center">
                <p class="text-h4 font-weight-bold">정말 편리하고 쉬운 기능</p>
            </v-sheet>
            <v-sheet width="90%" color="transparent" class="mx-auto">
                <v-carousel 
                    hide-delimiter-background
                    class="test mx-auto mb-16"
                    height="640"
                >  
                    <v-carousel-item>
                        <p class="mt-10 text-h5 font-weight-bold text-center">초고속 상품 등록</p>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">필수사항 입력하기</p>
                                <p class="mb-0 text-body-2">카테고리, 상품명, 과세구분, 판매가, 배송비 필수 5가지만 입력하면 상품등록이 완료됩니다.</p>
                            </v-card>
                        </v-sheet>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">공급가, 이전판매가 입력하고 통계자료 보기</p>
                                <p class="mb-0 text-body-2">판매가 외에 공급가와 이전판매가를 입력하면 매출통계 메뉴에서 상품판매에 대한 통계자료를 한눈에 확인 가능합니다.</p>
                            </v-card>
                        </v-sheet>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">묶음배송, 배송비 기본정책 설정하기</p>
                                <p class="mb-0 text-body-2">배송설정에서 묶음배송, 배송비 설정을 하면 상품등록 할 때 기본정책으로 자동 설정 됩니다.</p>
                            </v-card>
                        </v-sheet>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">상세한 옵션 등록하기</p>
                                <p class="mb-0 text-body-2">색상, 사이즈에 대한 옵션 등록을 할 경우 재고 설정이 가능하고 직접입력 요청사항이나 추가상품 등록도 가능합니다.</p>
                            </v-card>
                        </v-sheet>
                    </v-carousel-item>

                    <v-carousel-item>
                        <p class="mt-10 text-h5 font-weight-bold text-center">편리한 주문관리</p>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">주문상품 항목별 확인 및 현황파악</p>
                                <p class="mb-0 text-body-2">전체, 입금확인 중, 신규주문 등 항목별로 정리된 리스트로 확인 가능하여 다음 프로세스로 진행하기 쉽습니다.</p>
                            </v-card>
                        </v-sheet>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">옵션 설정하여 엑셀 다운받기</p>
                                <p class="mb-0 text-body-2">환경설정에서 원하는 엑셀옵션을 설정하면 주문관리에서 설정옵션으로 다운로드가 가능합니다.</p>
                            </v-card>
                        </v-sheet>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">상품별로 송장번호 일괄등록</p>
                                <p class="mb-0 text-body-2">다운받은 엑셀에 송장번호를 입력하고 송장일괄등록을 통해 파일을 업로드하면 선택한 리스트의 송장등록이 완료됩니다.</p>
                            </v-card>
                        </v-sheet>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">주문상태 변경하기</p>
                                <p class="mb-0 text-body-2">입금내역이나 결제완료 확인 후 주문상태를 변경하여 구매자가 처리상태를 확인할 수 있습니다.</p>
                            </v-card>
                        </v-sheet>
                    </v-carousel-item>

                    <v-carousel-item>
                        <p class="mt-10 text-h5 font-weight-bold text-center">보기편한 매출/통계</p>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">매출 리스트</p>
                                <p class="mb-0 text-body-2">판매상품의 결제수단별/기간별 매출을 리스트로 확인가능하고 검색 또한 가능합니다.</p>
                            </v-card>
                        </v-sheet>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">일별/월별 통계</p>
                                <p class="mb-0 text-body-2">주문 데이터를 수집 후 일별/월별로 구분해 통계자료를 확인하실 수 있습니다.</p>
                            </v-card>
                        </v-sheet>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">상품별 통계</p>
                                <p class="mb-0 text-body-2">판매기간을 기준으로 상품의 최대/최소 판매 여부를 확인해 향후 상품 판매계획에 활용할 수 있습니다.</p>
                            </v-card>
                        </v-sheet>
                    </v-carousel-item>

                    <v-carousel-item>
                        <p class="mt-10 text-h5 font-weight-bold text-center">자동메시지 관리기능</p>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">단문/장문 메시지 어떤 종류든 저렴한 비용</p>
                                <p class="mb-0 text-body-2">판매상품의 결제수단별/기간별 매출을 리스트로 확인가능하고 검색 또한 가능합니다.</p>
                            </v-card>
                        </v-sheet>
                        <v-sheet>
                            <v-card flat class="text-center py-3 px-2 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">서비스설정</p>
                                <p class="mb-0 text-body-2">
                                    SMS발신번호 등록요청을 하고, 메시지 내용을 입력합니다.<br/>
                                    상태 별 메시지는 무통장주문, 주문완료, 입금확인, 상품배송, 주문취소에 대한 <br/>
                                    내용을 입력할 수 있고 기타 메시지 설정도 가능합니다.
                                </p>
                            </v-card>
                        </v-sheet>
                        <v-sheet>
                            <v-card flat class="text-center py-3 my-2" style="background:#dabfbe">
                                <p class="mb-1 text-subtitle-1 font-weight-bold">발송내역 조회</p>
                                <p class="mb-0 text-body-2">SMS/알림톡이 오류 없이 발송됐는지 확인할 수 있도록 내역조회가 가능합니다.</p>
                            </v-card>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>

import MainFunctionView from "../main/MainFunctionView";
import MainFunctionView2 from "@/views/page/main/MainFunctionView2";

export default {
  components:{
    MainFunctionView2,
    MainFunctionView
  },
  data(){
    return {
      tabIndex:0
    }
  }
}
</script>
<style>
.test .v-btn{
    background:white;
}

.test .v-btn .v-btn__content .v-icon{
    color: #a87675;
}

.v-carousel__controls__item{
    color: #a87675 !important
}
</style>