<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet v-if="$vuetify.breakpoint.mdAndUp" style="padding-top:100px; padding-bottom:150px;overflow-x: auto" width="1274" class="mx-auto">
          <v-tabs
              background-color="#EFEBE9"
              color="white"
              next-icon="mdi-arrow-right-bold-box-outline"
              prev-icon="mdi-arrow-left-bold-box-outline"
              show-arrows
              v-model="tabIndex"
              @change="tabChanged"
              centered
          >
            <v-tabs-slider color="#4E342E"></v-tabs-slider>
            <v-tab
                color="#a87675"
                key="notice"
            >
              <label style="color: black;font-weight: bolder;cursor:pointer;">공지사항</label>
            </v-tab>
            <v-tab
                color="#a87675"
                key="qna"
            >
              <label style="color: black;font-weight: bolder;cursor:pointer;">Q & A</label>
            </v-tab>
            <v-tab
                color="#a87675"
                key="migration"
            >
              <label style="color: black;font-weight: bolder;cursor:pointer;">상담</label>
            </v-tab>
          </v-tabs>
            <v-sheet width="1274" class="mt-10 mx-auto px-6" v-if="board_type==='notice' || board_type ==='qna'">
              <cs_info
                  v-bind:boardType="board_type"
              />
              <v-sheet class="d-flex mx-auto mt-5" style="position: relative">
                <p class="text-h4 font-weight-bold" v-if="board_type==='notice' || board_type ==='qna'">{{board_type==='notice'? '공지사항':'Q&A'}}</p>
                <div v-if="board_type==='qna'" style="position: absolute;right: 0px">
                  <v-btn tile depressed left color="#a87675" dark large class="px-7 ml-4"
                         to="/main/qa/post"
                  >질문하기</v-btn>
                </div>
              </v-sheet>

              <v-divider color="#9F6564"></v-divider>
              <v-data-table
                    tile
                    class="cs_table"
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    loading-text="데이터를 불러오는 중입니다..."
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr style="cursor:pointer;" @click="goToReadNotice(item)" v-if="board_type==='notice'">
                            <td width="120">{{item.seq}}</td>
                            <td>{{item.title}}</td>
                            <td width="150">관리자</td>
                            <td width="240">{{item.createdAt}}</td>
                        </tr>
                      <tr style="cursor:pointer;" @click="goToReadQA(item)" v-if="board_type==='qna'">
                        <td width="120"><font class="font-weight-bold ml-2">Q</font></td>
                        <td><v-icon small left>mdi-lock-outline</v-icon>비공개 게시글입니다.</td>
                        <td width="150">{{item.writer}}</td>
                        <td width="120">
                          <span v-if="item.replied === true">답변</span>
                          <span v-else>미답변</span>
                        </td>
                        <td width="240">{{item.registeredAt}}</td>
                      </tr>
                    </template>
                  <template v-slot:no-data>
                    <div style="line-height: 100px">
                      <span style="font-weight: bold;font-size: 15px">게시글이 없습니다.</span>
                    </div>
                  </template>
                </v-data-table>
                <v-divider color="#9F6564"></v-divider>
                <!-- 페이지 -->
                <v-pagination
                    dep
                    class="my-6"
                    color="#A87675"
                    v-model="page"
                    :length="page_length"
                    @input="pageChanged"
                ></v-pagination>
            </v-sheet>
            <MigrationQa v-if="board_type ==='migration'" class="mt-12"/>
        </v-sheet>

        <!-- 모바일 -->
        <v-sheet v-if="!$vuetify.breakpoint.mdAndUp" style="padding-top:50px; padding-bottom:300px;">
          <v-tabs
              background-color="#EFEBE9"
              color="white"
              next-icon="mdi-arrow-right-bold-box-outline"
              prev-icon="mdi-arrow-left-bold-box-outline"
              show-arrows
              v-model="tabIndex"
              @change="tabChanged"
              centered
          >
            <v-tabs-slider color="#4E342E"></v-tabs-slider>
            <v-tab
                color="#a87675"
                key="notice"
            >
              <label style="color: black;font-weight: bolder;cursor:pointer;">공지사항</label>
            </v-tab>
            <v-tab
                color="#a87675"
                key="qna"
            >
              <label style="color: black;font-weight: bolder;cursor:pointer;">Q & A</label>
            </v-tab>
            <v-tab
                color="#a87675"
                key="migration"
            >
              <label style="color: black;font-weight: bolder;cursor:pointer;">상담</label>
            </v-tab>
          </v-tabs>

            <v-sheet class="mt-10 px-4" v-if="board_type==='notice' || board_type ==='qna'">
              <cs_info
                  v-bind:boardType="board_type"
              />
              <v-sheet v-if="board_type==='qna'"  class="d-flex mt-5" style="position: relative">
                <p class="text-h6 font-weight-bold">Q&A</p>
                <div style="position: absolute;right: 0px">
                  <v-btn tile depressed left color="#a87675" dark class="px-7 ml-4"
                         to="/main/qa/post"
                         small
                  >질문하기</v-btn>
                </div>
              </v-sheet>
              <v-sheet v-else class="text-center" style="position: relative">
                <p class="text-h6 font-weight-bold">공지사항</p>
              </v-sheet>
                <v-divider color="#9F6564"></v-divider>
                <v-data-table
                    dense
                    tile
                    class="cs_table_mobile"
                    :headers="headers2"
                    :items="items"
                    hide-default-footer
                    mobile-breakpoint="0"
                >
                    <template v-slot:item="{ item }">
                      <tr style="cursor:pointer;" @click="goToReadNotice(item)" v-if="board_type==='notice'">
                          <td>{{item.title}}</td>
                          <td>{{item.createdAt}}</td>
                      </tr>
                      <tr style="cursor:pointer;" @click="goToReadQA(item)" v-if="board_type==='qna'">
                        <td><font class="font-weight-bold ml-2">Q</font></td>
                        <td>
                          <div class="py-2">
                            <font v-if="$vuetify.breakpoint.smAndUp"><v-icon small left>mdi-lock-outline</v-icon>비공개 게시글입니다.</font>
                            <font v-if="!$vuetify.breakpoint.smAndUp"><v-icon small left>mdi-lock-outline</v-icon>비공개</font>
                            <div class="mt-2">
                              <span>{{item.writer}}</span>
                              <span class="ml-8">{{item.createdAt}}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span v-if="item.replied === true">답변</span>
                          <span v-else>미답변</span>
                        </td>
                      </tr>
                    </template>
                  <template v-slot:no-data>
                    <div style="line-height: 100px">
                      <span style="font-weight: bold;font-size: 15px">게시글이 없습니다.</span>
                    </div>
                  </template>
                </v-data-table>
                <v-divider color="#9F6564"></v-divider>
                <!-- 페이지 -->
                <v-pagination
                    class="my-6"
                    color="#A87675"
                    v-model="page"
                    :length="page_length"
                    @input="pageChanged"
                ></v-pagination>
            </v-sheet>
            <MigrationQa v-if="board_type ==='migration'" class="mt-12"/>
        </v-sheet>
      <QnAAuth
          ref="qnAAuth"
      />
    </v-sheet>
</template>
<script>
const {BoardApi} = require("@/api/BoardApi");
import QnAAuth from './dialog/QnAAuth';
import cs_info from './cs/cs_info';
import MigrationQa from './migration_qa';

export default {
  components:{
    QnAAuth,
    MigrationQa,
    cs_info
  },
  created() {
  },
  data: () => ({
        tabIndex:0,
        headers: [
            {text: '번호', value: 'id'},
            {text: '제목', value: 'title'},
            {text: '작성자', value: 'writer'},
            {text: '작성일', value: 'created'},
        ],
        headers2: [
          {text: '제목', value: 'title'},
          {text: '작성일', value: 'created',width:150},
        ],
        notice_headers: [
            {text: '번호', value: 'id'},
            {text: '제목', value: 'title'},
            {text: '작성자', value: 'writer'},
            {text: '작성일', value: 'created'},
        ],
        notice_headers2: [
          {text: '번호', value: 'id'},
          {text: '제목', value: 'title'},
          {text: '작성일', value: 'created'},
        ],

        qna_headers: [
            {text: 'Q&A', value: 'QnA'},
            {text: '제목', value: 'title'},
            {text: '작성자', value: 'writer'},
            {text: '답변여부', value: 'is_answered'},
            {text: '작성날짜', value: 'created'},
        ],

        qna_headers2: [
          {text: 'Q&A', value: 'QnA'},
          {text: '제목/작성자', value: 'title'},
          {text: '답변여부', value: 'is_answered'},
        ],
        items: [],
        board_type:"notice",
        page: 1,
        page_length: 1,
        loading:false,
        authenticated:false,

  }),

    mounted(){
        if(this.$route.query.type){
          if(this.$route.query.type ==='notice'){
            this.tabIndex=0
            this.board_type='notice'
          }
          if(this.$route.query.type ==='qna'){
            this.tabIndex=1
            this.board_type='qna'
          }
          if(this.$route.query.type ==='migration'){
            this.tabIndex=2
            this.board_type='migration'
          }
          this.loadBoardPost(this.$route.query.type)

        }else{
          this.tabIndex=0
          this.headers=this.notice_headers
          this.headers2=this.notice_headers2
          this.$router.replace({query: { type: this.board_type }})
          this.loadNotice()
        }
      this.authenticated = this.$store.getters.isAuthenticated
    },

    watch: {
        board_type(){
            if(this.board_type==="notice"){
                this.headers=this.notice_headers
                this.headers2=this.notice_headers2
            }else if(this.board_type==="qna"){
              this.headers=this.qna_headers
              this.headers2=this.qna_headers2
            }
        }
    },

    methods: {
      tabChanged(value){
        if(value === 0){
          if(this.board_type === 'notice'){
            return
          }
          this.board_type='notice'
          this.$router.replace({query: { type: this.board_type }})
          //this.loadBoardPost('notice')
        }
        if(value === 1){
          if(this.board_type === 'qna'){
            return
          }
          this.board_type='qna'
          this.$router.replace({query: { type: this.board_type }})
          //this.loadBoardPost('qna')
        }
        if(value === 2){
          if(this.board_type === 'migration'){
            return
          }
          this.board_type='migration'
          this.$router.replace({query: { type: this.board_type }})
          //this.loadBoardPost('migration')
        }
      },
      goToReadNotice(item){
        this.$router.push('/main/cs/read?id='+item.id)
      },
      goToReadQA(item){
        if(this.authenticated === true){
          const myInfo =  this.$store.getters.getMyInfo
          if(item.memberId === myInfo.id){
            this.$router.push('/main/qa/read?id='+item.id+'&authenticated=1')

            return
          }
        }
        this.$refs.qnAAuth.open(item.id,(ret)=>{
          if(ret.res === true){
            this.$refs.qnAAuth.close()
            this.$router.push('/main/qa/read?id='+item.id+'&key='+ret.resData)
          }else{
            alert('요청 실패입니다. 다시 시도해 주세요')
          }
        })
      },

      loadQA(){
        const page = {
          size : 10,
          page : 0,
        }
        this.loading=true
        BoardApi.getNonMemberQuestions(page).then(res=>{
          console.log('getNonMemberQuestions',res)
          this.page = res.page+1
          this.page_length=res.totalPages
          this.items=res.list
          this.loading=false
        }).catch(()=>{
          alert('알수 없는 에러가 발생하였습니다')
          this.loading=false
        })
      },
      loadNotice(){
        const page = {
          size : 10,
          page : 0,
        }
        this.loading=true
        BoardApi.getAdminNotices(page).then(res=>{
          console.log('loadNotice',res)
          this.page = res.page+1
          this.page_length=res.totalPages
          this.items=res.list
          this.loading=false
        }).catch(()=>{
          alert('알수 없는 에러가 발생하였습니다')
          this.loading=false
        })
      },
      loadBoardPost(type){
        if(type === 'qna'){
          this.loadQA()
        }else if(type === 'notice'){
          this.loadNotice()
        }
      },
      pageChanged(page){
        console.log('pageChanged',page)
        if(this.board_type==='qna'){
          const requestPage = {
            size : 10,
            page : page-1,
          }
          console.log('pageChanged 요청',requestPage)
          this.loading=true
          BoardApi.getNonMemberQuestions(requestPage).then(res=>{
            console.log('getNonMemberQuestions',res)
            this.page = res.page+1
            this.page_length=res.totalPages
            this.items=res.list
            this.loading=false
          }).catch(()=>{
            this.loading=false
          })
        }else if(this.board_type==='notice'){
          const requestPage = {
            size : 10,
            page : page-1,
          }
          console.log('pageChanged 요청',requestPage)
          this.loading=true
          BoardApi.getAdminNoticesByPage(requestPage).then(res=>{
            console.log('getAdminNotices',res)
            this.page = res.page+1
            this.page_length=res.totalPages
            this.items=res.list
            this.loading=false
          }).catch(()=>{
            this.loading=false
          })
        }
      }
    }
}
</script>
<style>
.cs_table{
    max-width:1274px;
}

.cs_table thead th {
    height:50px !important;
    font-size: 16px !important;
}

.cs_table tbody td {
    height:50px !important;
    font-size: 16px !important;
}

.cs_table_mobile{
    max-width:1274px;
}

.cs_table_mobile thead th {
    font-size: 12px !important;
}

.cs_table_mobile tbody td {
    font-size: 12px !important;
}
</style>